import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { initializeApp } from 'firebase/app';
import store from './store';

// import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";

const app = createApp(App)


const firebaseConfig = {
  // Your Firebase configuration
  apiKey: "AIzaSyCCypv0OGcCUmH2p1o2TjwVteSN_CLzXz0",
  authDomain: "login.stokify.in",
  projectId: "stokify-8f594",
  storageBucket: "stokify-8f594.appspot.com",
  messagingSenderId: "445441596417",
  appId: "1:445441596417:web:1b516fc2a3c005e87eb400",
  measurementId: "G-H5V252GSB2"
};

// Initialize Firebase
initializeApp(firebaseConfig)

// if (location.hostname === "localhost") {
//   connectAuthEmulator(getAuth(), "http://localhost:9099");
// }

// app.use(store)
app.use(store);
app.use(router)

app.mount('#app')



